import React from "react";
import Layout from "../components/layout";
import Clients from "../components/contact/Clients";

export default function contact() {
  return (
    <div>
      <Layout>
        <Clients />
      </Layout>
    </div>
  );
}
