import * as React from "react";
import Header from "./header";
import Footer from "./footer";
import { useEffect } from "react";

const Layout = ({children}) => {
    useEffect(() =>{
        window.scrollTo(0, 0)
      })
    return (
        <div style={{width: '100vw', overflowX: 'hidden'}}>
            <Header />
                {children}
            <Footer />
        </div>
    )
}

export default Layout