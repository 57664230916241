import React from "react";
import styled from "styled-components";
import IconPhone from "../../Assets/icon_phone.svg";
import IconMail from "../../Assets/icon_mail.svg";
import Whats2 from "../../Assets/icon_whats_black.svg";
import ContactImage from "../../Assets/contact_linkedin.webp";
import ContactImageDesktop from "../../Assets/contact_linkedin_desktop.webp";
import foto1 from "../../Assets/foto1.webp";
import foto2 from "../../Assets/foto2.webp";
import foto3M from "../../Assets/foto3Mobile.webp";
import foto3D from "../../Assets/foto3Desktop.webp";
import foto1D from "../../Assets/foto1D.webp";
import foto2D from "../../Assets/foto2D.webp";
import "../../css/typographyc.css";

const StyledClients = styled.div`
  background-color: var(--dark-back);
  color: white;
  font-family: "Noto_sans_regular";
  font-style: normal;
  padding: 100px 10px 76px;
  h1 {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
  }
  h2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-top: 40px;
    margin-bottom: 44px;
  }
  p {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    margin-left: 10px;
  }
  .titles {
    padding: 0 16px;
    p{
      font-family: "Noto_sans_regular";
    }
  }
  .item_contact {
    display: flex;
    flex-direction: column;
    gap: 16px;
    .item {
      display: flex;
      align-items: center;
      a {
          margin-left: 12px;
          color: white;
        }
    }
    .display_button {
      display: none;
      a {
        color: black;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 191px;
        border-radius: 45px;
        height: 48px;
        border: none;
        cursor: pointer;
        background-color: var(--green-button);
        img {
          width: 24px;
        }
        p {
          margin-left: 12px;
        }
    
      }
    }
  }
  .image_contact {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    .ima_des_linkedin {
      display: none;
    }
  }
  @media (min-width: 1200px) {
    display: flex;
    justify-content: space-evenly;
    /* padding: 100px 60px; */
    h1 {
      font-size: 28px;
    }
    h2 {
      font-size: 20px;
    }
    p {
      font-size: 16px;
    }
    .titles {
      max-width: 447px;
    }
    .image_contact {
      margin-top: 0;
      .ima_des_linkedin {
        display: block;
      }
      .ima_mob_linkedin {
        display: none;
      }
      img {
        width: 600px;
        height: 400px;
      }
    }
    .item_contact {
      .display_button {
        margin-top: 87px;
        display: block;
      }
    }
  }
`;
const StyledImages = styled.div`
  background-color: var(--dark-back);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .images_top {
    padding: 0 20px;
    display: flex;
    max-width: 800px;
    gap: 20px;
    .desktop {
      display: none;
    }
    img {
      :last-child {
        display: none;
      }
    }
  }
  .image_bottom {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    img {
      width: 340px;
    }
  }
  @media (min-width: 1200px) {
    .images_top {
      .mobile {
        display: none;
      }
      .desktop {
        display: block;
      }
      img {
        width: 210px;
        :last-child {
          display: block;
        }
      }
    }
    .image_bottom {
      display: none;
    }
  }
`;
export default function Clients() {
  return (
    <>
      <StyledClients>
        <div className="titles">
          <h1>Ofrécele a tus clientes las mejores soluciones de seguridad</h1>
          <h2>
            Contáctanos para aclarar cualquier duda o darnos tus comentarios.
          </h2>
          <div className="item_contact">
            <div className="item">
              <img src={IconPhone} alt="icon_phone" />
              <a href="tel:5586469532">+55 8646 9532</a>
            </div>
            <div className="item">
              <img src={IconMail} alt="icon_phone" />
              <a href="mailto:lets-do-business@datastream.com.mx">lets-do-business@datastream.com.mx</a>
            </div>
            <div className="item"></div>
            <div className="display_button">
            <a href="https://api.whatsapp.com/send?phone=525627161515">
                <img src={Whats2} alt="boton_whats" />
                <p>Contáctanos</p>
              </a>
            </div>
          </div>
        </div>

        <div className="image_contact">
          <img
            className="ima_mob_linkedin"
            src={ContactImage}
            alt="image_mobile"
          />
          <img
            className="ima_des_linkedin"
            src={ContactImageDesktop}
            alt="image_desktop"
          />
        </div>
      </StyledClients>
      <StyledImages>
        <div className="images_top">
          <img className="mobile" src={foto1} alt="pic_one" />
          <img className="mobile" src={foto2} alt="pic_two" />
          <img className="desktop" src={foto2D} alt="pic_three" />
          <img className="desktop" src={foto1D} alt="test" />
          <img className="desktop" src={foto3D} alt="test2" />
        </div>
        <div className="image_bottom">
          <img src={foto3M} alt="pic_desk" />
        </div>
      </StyledImages>
    </>
  );
}
